<template>
  <div class="shop-mission-statement">
    <span class="shop-mission-statement-label"> {{ statementLabel }}: </span>
    <span class="shop-mission-statement-text"> {{ statementText }} </span>
  </div>
</template>
<script>
export default {
  name: 'ShopMissionStatement',
  props: {
    statementLabel: {
      type: String,
      default: '',
    },
    statementText: {
      type: String,
      default: '',
    },
  },
}
</script>
<style lang="scss" scoped>
.shop-mission-statement {
  display: grid;
  grid-template-columns: 1fr 3fr;
  padding-top: spacing('small');
  padding-bottom: spacing('small');
  border-top: 1px solid var(--shop-mission-statement--line-color, #{$bronze});

  &:last-child {
    border-bottom: 1px solid
      var(--shop-mission-statement--line-color, #{$bronze});
  }

  .shop-mission-statement-label {
    @include caps('large');

    font-weight: 500;
  }

  .shop-mission-statement-text {
    @include p--medium;

    width: fit-content;
    margin-left: auto;
    line-height: 130%; // match label line height
  }
}

@media screen and (min-width: $tablet-landscape) {
  .shop-mission-statement {
    display: flex;
    justify-content: space-between;
  }
}
</style>
